import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApprovalDropdownComponent } from './approval-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SigningLawSectionRadioModule } from '../../upload-memos/loa-customizer/signing-law-section-radio/signing-law-section-radio.module';
import { ApproverTypeDropdownComponent } from './approver-type-dropdown/approver-type-dropdown.component';

@NgModule({
  declarations: [
    ApprovalDropdownComponent,
    ApproverTypeDropdownComponent,
  ],
  exports: [ApprovalDropdownComponent, ApproverTypeDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    SigningLawSectionRadioModule,
    TranslateModule,
  ],
})
export class ApprovalDropdownModule {}
